<style lang="scss">
#debug {
  display: none;
}

#frontend {
  .offenlegung-all {
    letter-spacing: 1px;

    .sub-headline {
      font-size: 140%;
      font-family: 'Helvetica Neue LT W05_75 Bold';
      letter-spacing: 1px;
      margin-top: 10px;
    }

    h2 {
      text-transform: none;
      letter-spacing: 1px;
      font-size: 180%;
    }

    a {
      display: inline-block;
      //border:1px solid red;
      font-family: 'Helvetica Neue LT W05_75 Bold';

      &:hover {
        color: #000;
      }
    }

    .smd-link {
      font-size: 140%;
      margin: 10px 0;
    }

    .line {
      width: 100%;
      border-top: 1px solid #000;
      margin-bottom: 80px;
      margin-top: 30px;
    }

    h4 {
      font-family: 'Helvetica Neue LT W05_75 Bold';
      margin-top: 40px;
    }

    .h4-bg-v1 {
      span {
        padding-bottom: 2px;
        display: inline-block;
        border-bottom: 3px solid #a998c6;
      }

    }

    .h4-bg-v2 {
      span {
        padding-bottom: 2px;
        display: inline-block;
        border-bottom: 3px solid #cbe7e5;
      }
    }

    ol {
      li {
        margin: 5px 0;
      }
    }

    .diagramm-content {
      margin-bottom: 15px;

      img {
        display: block;
        margin: 30px auto;
      }

      .txt-box {
        margin-bottom: 15px;

        padding: 20px 10px;
        text-align: center;

        span {

          display: inline-block;
          font-size: 180%;
          font-family: 'Helvetica Neue LT W05 65 Medium';
          font-family: 'American Typewriter ITC W04 Md';
        }

        p {
          margin-bottom: 0;
        }
      }

      .bg-v1 {
        background: #a998c6;
      }

      .bg-v2 {
        background: #cbe7e5;
      }
    }

    .firmen-liste {
      margin-top: 80px;

      @media(max-width:568px) {
        margin-top: 40px;
      }

      h4 {
        margin-bottom: 40px;
      }

      h5 {
        margin-bottom: 30px;
      }

      span {

        display: inline-block;
        //text-decoration: underline;
        font-family: 'Helvetica Neue LT W05_75 Bold';

      }

      h6 {
        margin-top: 20px;
        font-size: 140%;
        font-family: 'Helvetica Neue LT W05 56 Italic';
      }

      p {
        margin-bottom: 30px;
      }
    }

    .border-box-left {
      border-left: 2px solid $blue;
    }

    $padding: 30px;

    .indent-1 {
      padding-left: 1.5 * $padding;
      margin-top: 20px;

      @media(max-width:568px) {
        padding-left: 0.5 * $padding;
      }

    }

    .indent-2 {
      margin-left: 1.5 * $padding;
      padding-left: 1.5 * $padding;
      border-left: 2px solid $coral;

      @media(max-width:568px) {
        margin-left: 0.5 * $padding;
        padding-left: 0.5 * $padding;
      }
    }

    .indent-3 {
      margin-left: 3.5 * $padding;
      padding-left: 1.5 * $padding;
      border-left: 2px solid $sand;

      @media(max-width:568px) {
        margin-left: 1 * $padding;
        padding-left: 0.5 * $padding;
      }
    }

    .indent-4 {
      margin-left: 4.5 * $padding;
      padding-left: 1.5 * $padding;
      border-left: 2px solid $mint;

      @media(max-width:568px) {
        margin-left: 1 * $padding;
        padding-left: 0.5 * $padding;
      }
    }
  }

  .italic-txt {
    font-family: 'Helvetica Neue LT W05 56 Italic';
  }
}
</style>

<template>
  <div class="offenlegung-all">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <h1>{{ $t('General.impressum') }}</h1>
            </div>
          </div>
        </div>
      </div>
      <div v-if="$i18n.locale == 'de'">
        <div class="row padding-tb-s justify-content-center">
          <div class="col-lg-10 col-11">
            <h2 class=" text-lg-center">Impressum / Informationen gem. §§ 24 MedienG, 14 UGB, 5 ECG</h2>
          </div>
        </div>
        <div class="row justify-content-center text-lg-center padding-b-s">
          <div class="col-lg-5 col-11 align-self-center">
            <p>
              Medieninhaber / Herausgeber / Redaktion:<br />
              Styria Media Group AG<br />
              Corporate Communications<br />
              Gadollaplatz 1<br />
              8010 Graz
            </p>

            <p>
              Firmenbuchnummer: FN 142663z<br />
              Firmenbuchgericht: Landesgericht f. ZRS Graz<br />
              UID-Nr: ATU 43133701
            </p>
          </div>
          <div class="col-lg-5 col-11 align-self-center">
            <p>
              Telefon: <a href="tel:+4331680630">+43 (0)316 8063-0</a><br />
              Fax : <a href="tel:+4331680631004">+43 (0)316 8063-1004</a><br />
              Email: <a href="mailto:communication@styria.com">communication@styria.com</a>
            </p>
            <p>
              Redaktionelle Verantwortung<br />
              Corporate Communications<br />
              E-Mail: <a href="mailto:communication@styria.com">communication@styria.com</a><br />
            </p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12 col-11 text-lg-center padding-tb-s">
            <h3>Webdesign</h3>
            <a href="https://www.styriamediadesign.at" target="_blank" class="smd-link">Styria Media Design</a><br />
            <p>Alexander Marko und Christian Wilms, Geschäftsleitung</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-7 col-11 text-lg-center padding-tb-s">
            <small>Alle Rechte vorbehalten. Eine Vervielfältigung zum eigenen und privaten Gebrauch gem. § 42 UrhG ist
              gestattet. Jegliche darüber hinausgehende Verwertung ist verboten. </small>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-8 col-11">
            <div class="line"></div>
            <div class="headline-box-std">
              <div class="headline-inner">
                <h1>{{ $t('General.offenlegung') }}</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8 col-11">

            <h4>Offenlegung der Styria Media Group AG und
              ihrer Tochtergesellschaften gemäß § 25 Mediengesetz
            </h4>
            <p>Styria Media Group AG, 8010 Graz, Gadollaplatz 1.</p>
            <p><strong>Unternehmensgegenstand:</strong> Medienwesen.</p>
            <p><strong>Vorstand:</strong> Mag. Markus Mair (Vorsitzender), DI (FH) Bernhard Kiener, Mag. Herwig Langanger.</p>
            <p><strong>Aufsichtsrat:</strong> Dr. Othmar Ederer (Vorsitzender),
              Univ.-Prof. Mag. Dr. Johannes Zollner (Stellvertreter des Vorsitzenden),
              Mag.a Eva Heigl, Mag.a Petra Schachner-Kröll, Ute Groß, Ing. Josef Klapsch, MMag. Werner Ressi </p>
            <p>Die <u>Styria Media Group AG</u> wurde schon im Jahr 1869 von einem <u>Verein</u> von Katholiken
              grundgelegt. Seither bemühen sich Mitarbeiterinnen und Mitarbeiter aus vielen Ländern, Kulturen und
              Religionen diesen weltanschaulichen Wurzeln durch einen besonders sorgfältigen Umgang mit der Würde des
              Menschen - im umfassenden Sinn - gerecht zu werden. Heute gehört die Styria Media Group AG weit
              überwiegend einer <u>gemeinnützigen</u> Privatstiftung, die selbst von Gesetzes wegen eigentümerloses
              Vermögen ist. Dieser Hintergrund macht die Styria Media Group AG zu einem Unternehmen, in dem Werte
              zählen. Er sorgt aber gleichzeitig auch dafür, dass die Styria Media Group AG selbst und ihre
              Tochterunternehmungen <u>unabhängig</u> sind, insbesondere auch von politischen Parteien,
              Interessensvertretungen und Kirchen. </p>

          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8 col-11 diagramm-content">
            <!--<h4>Eigentümer der Styria Media Group AG:</h4>-->
            <img src="@/assets/img/footer/offenlegung-diagramm-v2.jpg" alt="Diagramm" class="img-fluid" />
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="txt-box bg-v1">
                  <span>98,33 %</span>
                  <p>Katholischer Medien Verein Privatstiftung</p>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="txt-box bg-v2">
                  <span>1,67 %</span>
                  <p>Katholischer Medien Verein</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8 col-11">
            <h4 class="h4-bg-v1"><span>Katholischer Medien Verein Privatstiftung, Graz</span></h4>
            <p><strong>Stifter:</strong> Katholischer Medien Verein, Dr. Josef Heuberger, Dr. h. c. Franz
              Küberl sowie Regens Mag. Franz Josef Rauch.</p>
            <p><strong>Begünstigte:</strong> Der oder die Begünstigten sind vom Stiftungsvorstand zu
              bestimmen. Die Bestimmung hat unter Berücksichtigung des Zweckes der Stiftung ausschließlich für
              gemeinnützige, mildtätige oder kirchliche Zwecke zu erfolgen.</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8 col-11">
            <h4 class="h4-bg-v2"><span>Katholischer Medien Verein, Graz</span>
            </h4>
            <p><strong>Vorstand:</strong> Obmann: Dr. Othmar Ederer, Obmann-Stellvertreterin: Mag.a Eva Heigl, Kassier: Mag. Sieglinde Pailer, Schriftführer Mag. Herbert Peklar, MSc.</p>
            <strong>Vereinszweck:</strong>
            <ol>
              <li>Der Verein fördert christliche Medienarbeit, insbesondere die Bildung von Medienfachleuten, die
                Herstellung und Verbreitung von Medienwerken aller Art, die dem Geist der katholischen Kirche und ihrer
                Lehre, den Dialog zwischen Glauben und Wissenschaft, der ökumenischen und interreligiösen Begegnung, der
                katholischen Soziallehre, dem Schutz der Menschenwürde und den Grundsätzen der Demokratie und
                Völkerverständigung dienen.</li>
              <li>Der Verein verfolgt ausschließlich gemeinnützige, caritative und/oder kirchliche Zwecke im Sinne der
                §§ 34 ff Bundesabgabenordnung in der jeweils gültigen Fassung.


              </li>
            </ol>

          </div>
        </div>

        <div class="row justify-content-center firmen-liste">
          <div class="col-lg-8 col-11">
            <p>Im Sinne der medienrechtlichen Offenlegungspflicht, finden Sie im Folgenden eine Darstellung der
              diesbezüglich relevanten Inhaberschaften (im Sinne einer 100%-Beteiligung) der Styria Media Group AG. Dies
              stellt sohin kein abschließendes Organigramm der gesamten Inhaberschaften und Beteiligungen der Styria
              Media Group AG dar. Unter den einzelnen Firmenwortlauten finden Sie die Angabe zum jeweiligen
              Unternehmensgegenstand.</p>
            <h4>Die Styria Media Group AG ist Inhaberin von:</h4>
            <div class="border-box-left">
              <p class="indent-1">
                <span>Styria Medienhaus Wien GmbH, Wien</span><br>
                Vermögensverwaltung.
              </p>


              <p class="indent-2">
                <span>„Die Presse“ Verlags-Gesellschaft m.b.H. & Co KG, Wien</span><br />
                Herausgabe der Tageszeitung „Die Presse“, der Wochenzeitung „Die Presse“ am Sonntag und der Betrieb
                eines
                Onlinedienstes.
              </p>
              <p class="indent-2">
                <span>Content Performance Group GmbH, Wien</span><br />
                Herausgabe und Vertrieb von Content Marketing Produkten und der Betrieb eines Onlinedienstes.
              </p>
            </div>

            <div class="border-box-left">
              <p class="indent-1">
                <span>Top Times Medien GmbH, Graz</span><br />
                Herausgabe von Zeitschriften und Magazinen, insbesondere „SPORTaktiv“ und der Betrieb eines
                Onlinedienstes.
              </p>
            </div>
            <div class="border-box-left">
              <p class="indent-1">
                <span>Die Furche – Zeitschriften-Betriebs-Gesellschaft m.b.H. & Co KG, Wien</span><br />
                Herausgabe der Wochenzeitung „DIE FURCHE“ und der Betrieb eines Onlinedienstes.
              </p>
            </div>
            <div class="border-box-left">
              <p class="indent-1">
                <span>Styria Media Regional GmbH, Graz</span><br />
                Vermögensverwaltung.
              </p>

              <p class="indent-2">
                <span>Kleine Zeitung GmbH</span><br />
                Verlagswesen, insbesondere die Herausgabe der Tageszeitung „KLEINE ZEITUNG“, und der Betrieb eines
                Onlinedienstes sowie einer Werbeagentur.
              </p>
              <p class="indent-3">
                <span>Content Performance Group GmbH, Wien</span><br />
                Herausgabe und Vertrieb von Content Marketing Produkten und der Betrieb eines Onlinedienstes.
              </p>
              <p class="indent-3">
                <span>SDMP Beteiligungsverwaltungs GmbH, Graz</span><br>
                Vermögensverwaltung.

              </p>
              <p class="indent-4">
                <span>Styria Digital Marketplaces GmbH, Graz</span><br>
                Betrieb von Online-Plattformen.
              </p>

              <p class="indent-2">
                <span>Antenne Steiermark Regionalradio GmbH & Co KG, Graz</span><br />
                Hörfunk.
              </p>
              <p class="indent-2">
                <span>Antenne Kärnten – Regionalradio GmbH & Co KG, Klagenfurt</span><br />
                Hörfunk.
              </p>

            </div>
            <div class="border-box-left">
              <p class="indent-1">
                <span>Schlagerradio Flamingo GmbH, Graz</span><br />
                Hörfunk.
              </p>
            </div>
            <div class="border-box-left">
              <p class="indent-1">
                <span>Verlagsgruppe Styria GmbH & Co KG, Graz</span><br />
                Herstellung, Verbreitung, Handel und Verlag literarischer Werke aller Art und Betrieb eines
                Internetportals.
              </p>
            </div>
            <div class="border-box-left">
              <p class="indent-1">
                <span>Styria Services Holding GmbH, Graz</span><br />
                Holdinggesellschaft.
              </p>

              <p class="indent-2">
                <span>rca radio content austria GmbH, Graz</span><br />
                Erstellung, Vertrieb und Erwerb von audiovisuellen Inhalten.
              </p>
            </div>


            <!--- -->


          </div>
        </div>
      </div>
      <div v-if="$i18n.locale == 'en'">
        <div class="row justify-content-center text-lg-center padding-b-s">
          <div class="col-lg-8 col-11 align-self-center">
            <p>
              Editorial Responsibility<br />
              Corporate Communication <br />
              Gadollaplatz 1<br />
              8010 Graz
            </p>

            <p>
              Company register number: FN 142663z<br />
              Commercial court: Landesgericht f. ZRS Graz<br />
              UID No: ATU 43133701
            </p>
          </div>
          <div class="col-lg-8 col-11 align-self-center">
            <p>
              Editorial responsibility<br />
              Corporate Communication<br />
              Telephone: <a href="tel:+4331680631078">+43/316/8063-1078</a><br />
              Email: <a href="mailto:communication@styria.com">communication@styria.com</a><br />
            </p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12 text-lg-center padding-tb-s">
            <h3>Web design</h3>
            <a href="https://www.styriamediadesign.at" target="_blank" class="smd-link">Styria Media Design</a><br />
            <p>Alexander Marko and Christian Wilms, Management</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Footer from '../../../components/Frontend/Footer.vue';

import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Offenlegung | Styria Media Group',
      keywords: ['Offenlegung', 'imprint', 'impressum', 'styria media group', 'styria'],
      description: 'Offenlegung. Styria Media Group AG, Gadollaplatz 1 8010 Graz',
      image: require('@/assets/img/og/startseite.png'),
    },
    en: {
      title: 'Disclosure | Styria Media Group',
      keywords: ['disclosure', 'styria media group', 'styria'],
      description: 'disclosure. Styria Media Group AG, Gadollaplatz 1 8010 Graz',
      image: require('@/assets/img/og/startseite.png'),
    },
  },
  components: {
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
